<template>
  <div>
    <px-capa :banner-capa="header">
      <template v-slot:title>
        Compre granalha para sua empresa com a Proxpect
      </template>
      <template v-slot:text>
        Cadastre-se e compre agora, sem precisar de cotações
      </template>
    </px-capa>
    <v-container class="mkt-content-container mt-5">
      <px-home-page-block
        show-link
        :show-price="false"
        link="/q/c/28"
        class="mt-5"
        :query="query"
        title="Conheça nossos produtos"
        :qtd-ofertas="20"/>
      <px-landing-page-whatsapp class="mt-5 mb-12" phone-number="5548992120020"/>
      <px-landing-page-banners class="mt-12"
                               :banners="banners"/>
      <px-banner-cadastro-email class="mt-5 mb-12"
                                :is-link="false"
                                :text="bannerCadastroText"
                                @click="onClickBannerCadastro"/>
      <px-como-funciona class="mt-12 mb-12"/>
      <px-vantagens class="mt-12 mb-12"/>
      <px-faq-landing-page class="mt-12 mb-10"/>
      <div class="headline font-weight-bold ml-2 mb-5">
        Precisa falar conosco?
      </div>
      <v-row class="mt-10">
        <v-col cols="12">
          <px-rd-duvida-form-subscription/>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import HomePageBlock from '@/modules/home-page/HomePageBlock.vue';
import LandingPageBanners from '@/modules/landing-page/LandingPageBanners.vue';
import ComoFunciona from '@/modules/institucional/ComoFunciona.vue';
import Vantagens from '@/modules/institucional/Vantagens.vue';
import Capa from '@/modules/landing-page/Capa.vue';
import FaqLandingPage from '@/modules/landing-page/FaqLandingPage.vue';
import PxBannerCadastroEmail from '@/components/marketing/PxBannerCadastroEmail.vue';
import gridMixin from '@/mixins/grid-mixin';
import RdDuvidaFormSubscription from '../../integration/rd/RdDuvidaFormSubscription.vue';
import LandingPageWhatsapp from '../LandingPageWhatsapp.vue';
import header from '../../../assets/landing-page/granalha/header-granalha.png';
import granalha1 from '../../../assets/landing-page/max-granalha/vidro 800 -desk.png';
import granalha1Mobile from '../../../assets/landing-page/max-granalha/vidro 800 -mobile.png';
import granalha2 from '../../../assets/landing-page/max-granalha/ligas metalicas -DESK.png';
import granalha2Mobile from '../../../assets/landing-page/max-granalha/ligas metalicas -mobile.png';
import granalha3 from '../../../assets/landing-page/max-granalha/microesfera - desk.png';
import granalha3Mobile from '../../../assets/landing-page/max-granalha/microesfera - mobile.png';
import granalha4 from '../../../assets/landing-page/max-granalha/OXIDO 40- desk.png';
import granalha4Mobile from '../../../assets/landing-page/max-granalha/OXIDO 40 - mobile.png';
import granalha5 from '../../../assets/landing-page/max-granalha/OXIDO 46 - desk.png';
import granalha5Mobile from '../../../assets/landing-page/max-granalha/OXIDO 46 - mobile.png';
import granalha6 from '../../../assets/landing-page/max-granalha/oxido 120 -desk.png';
import granalha6Mobile from '../../../assets/landing-page/max-granalha/oxido 120 -mobile.png';

export default {
  mixins: [gridMixin],
  data() {
    return {
      header,
      query: 'disponivel is true and fornecedor.id = 2434 order by random',
      bannerCadastroText: 'Cadastre-se agora e começe a comprar os produtos que sua empresa precisa',
      banners: [],
    };
  },
  methods: {
    onClickBannerCadastro() {
      this.$router.push({
        name: 'public.criar-conta',
      });
    },
  },
  created() {
    this.banners = [
      {
        foto: this.getGridImg(granalha1, granalha1Mobile),
        link: '/oferta/93331',
      },
      {
        foto: this.getGridImg(granalha2, granalha2Mobile),
        link: '/oferta/93574',
      },
      {
        foto: this.getGridImg(granalha3, granalha3Mobile),
        link: '/oferta/93322',
      },
      {
        foto: this.getGridImg(granalha4, granalha4Mobile),
        link: '/oferta/93567',
      },
      {
        foto: this.getGridImg(granalha5, granalha5Mobile),
        link: '/oferta/93568',
      },
      {
        foto: this.getGridImg(granalha6, granalha6Mobile),
        link: '/oferta/93572',
      },
    ];
  },
  components: {
    pxHomePageBlock: HomePageBlock,
    pxLandingPageBanners: LandingPageBanners,
    pxRdDuvidaFormSubscription: RdDuvidaFormSubscription,
    pxComoFunciona: ComoFunciona,
    pxVantagens: Vantagens,
    pxCapa: Capa,
    pxFaqLandingPage: FaqLandingPage,
    PxBannerCadastroEmail,
    pxLandingPageWhatsapp: LandingPageWhatsapp,
  },
};
</script>
